import React, { useState, Fragment, useEffect, useCallback, useRef } from "react";
import { MENUITEMS } from "../../../components/common/sidebar-component/menu";
import { Link } from "react-router-dom";
import { Search } from "react-feather";
import { useSelector } from "react-redux";
import styled from "styled-components";

const SearchHeader = () => {
  const mainmenu = JSON.parse(sessionStorage.getItem("features" || ""))[0]
    .children;
  const [searchValue, setsearchValue] = useState("");
  const [searchOpen, setsearchOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const mixLayout = useSelector((state) => state?.Customizer?.mix_layout);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      setsearchOpen(false);
      setsearchValue("");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items = [];
    // eslint-disable-next-line
    mainmenu.filter((menuItems) => {
      if (
        menuItems.label.toLowerCase().includes(keyword) &&
        menuItems.children?.length === 0
      ) {
        items.push(menuItems);
      }
      if (!menuItems.children) return false;
      // eslint-disable-next-line
      menuItems.children.filter((subItems) => {
        // eslint-disable-next-line
        if (
          subItems.label.toLowerCase().includes(keyword) &&
          subItems.children?.length === 0
        ) {
          subItems.icon = menuItems.icon;
          items.push(subItems);
          // eslint-disable-next-line
        }
        if (!subItems.children) return false;
        // eslint-disable-next-line
        subItems.children.filter((suSubItems) => {
          if (suSubItems.label.toLowerCase().includes(keyword)) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
        });
      });

      checkSearchResultEmpty(items);
      setsearchValue(items);
    });
  };

  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      document.querySelector(".empty-menu").classList.add("is-open");
    } else {
      document.querySelector(".empty-menu").classList.remove("is-open");
    }
  };

  const addFix = () => {
    document.querySelector(".Typeahead-menu").classList.add("is-open");
    // document.body.classList.add("offcanvas");
  };

  const removeFix = () => {
    setsearchValue("");
    document.querySelector(".Typeahead-menu").classList.remove("is-open");
    // document.body.classList.remove("offcanvas");
  };

  const toggleBtn = () => {
    if (searchOpen) {
      setsearchOpen(!searchOpen);
      document.querySelector(".searchIcon").classList.add("open");
    } else {
      setsearchOpen(!searchOpen);
      document.querySelector(".searchIcon").classList.remove("open");
    }
  };

  const [screen, setScreen] = useState();
  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        console.log("isRunnnign+++")
        document
          .querySelector(".Typeahead-menu")
          .classList.remove("is-open");
        document.querySelector(".empty-menu").classList.remove("is-open");
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const dividerStyle =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? "#eff0f1"
      : "#374558";
  const searchStyle =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? "#f6f7fb"
      : "#293240";
  const searchStyleBorder =
    mixLayout == "light-only" || mixLayout == "dark-body-only" ? "" : "#374558";
  const searchStyleText =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? ""
      : "hsla(0,0%,100%,.85)";
  const aciveClassIs =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? ""
      : "MenuStyleDark";
  const searchResultBorder =
    mixLayout == "light-only" || mixLayout == "dark-body-only"
      ? "1px solid Black"
      : "2px solid hsla(0,0%,100%,.85)";

  return (
    <Fragment>
      <div
        className={
          localStorage.getItem("wrapper") === "horizontal_sidebar"
            ? "col-12 px-0 pt-3"
            : "col-12 px-0"
        }
      >
        <form className="col-12 form-inline search-form">
          <div style={{ width: "inherit" }} className="position-relative">
            <input
              ref={inputRef}
              style={{
                width:
                  localStorage.getItem("wrapper") === "horizontal_sidebar"
                    ? "99%"
                    : "inherit",
                backgroundColor: searchStyle,
                borderColor: searchStyleBorder,
                color: searchStyleText,
              }}
              className="form-control-plaintext searchIcon"
              type="text"
              placeholder="search"
              // defaultValue={searchValue}
              value={selectedItem}
              onBlur={() => {
                // document
                //   .querySelector(".Typeahead-menu")
                //   .classList.remove("is-open");
                // document.querySelector(".empty-menu").classList.remove("is-open");
              }}
              onClick={() => {
                if (selectedItem && !searchValue?.length) {
                  document.querySelector(".empty-menu").classList.add("is-open");
                }
                selectedItem && addFix()
              }}
              onChange={(e) => {
                setsearchValue(e.target.value?.toLowerCase());
                handleSearchKeyword(e.target.value?.toLowerCase());
                setSelectedItem(e.target.value);
              }}
            />
            <div
              style={{ top: "12px", left: "12px" }}
              className="position-absolute"
            >
              <Search width={18} height={18} color="#8e8e8e" />
              <hr
                style={{
                  position: "absolute",
                  top: "-15px",
                  left: "30px",
                  border: "none",
                  height: "20px",
                  borderRight: "1px solid",
                  borderColor: dividerStyle,
                }}
              ></hr>
            </div>
            {/* <span style={{ top: "12px" }} className="position-absolute d-sm-none mobile-search" onClick={toggleBtn}>
              <Search />
            </span> */}

            {searchValue ? (
              <div
                ref={dropdownRef}
                style={{
                  border: searchResultBorder,
                  width: "98%",
                  top: screen <= 575 ? "115%" : "",
                  backgroundColor: searchStyle,
                  color: searchStyleText,
                }}
                className="Typeahead-menu custom-scrollbar"
                id="search-outer"
              >
                {
                  searchValue.map((data, index) => {
                    return (
                      <SearchMenuStyle >
                        <div
                          id={aciveClassIs}
                          className="ProfileCard u-cf"
                          key={index}
                        >
                          <div className="ProfileCard-avatar">
                            {/* <data.icon /> */}
                            <i className={data.icon}></i>
                          </div>
                          <div className="ProfileCard-details">
                            <div className="ProfileCard-realName">
                              <Link
                                style={{ color: searchStyleText }}
                                to={`${process.env.PUBLIC_URL}${data.route}`}
                                className="realname"
                                onClick={(e) => {
                                  handleSearchKeyword(data.label?.toLowerCase());
                                  setSelectedItem(data.label);
                                  document.querySelector(".Typeahead-menu").classList.remove("is-open");
                                }}
                              >
                                {data.label}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </SearchMenuStyle>
                    );
                  })}
              </div>
            ) : (
              ""
            )}

            <div
              style={{
                border: searchResultBorder,
                backgroundColor: searchStyle,
                color: searchStyleText,
              }}
              id="NoResultFound"
              className="Typeahead-menu empty-menu"
            >
              <div className="tt-dataset tt-dataset-0">
                <div className="EmptyMessage">{"No result found."}</div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default SearchHeader;
const SearchMenuStyle = styled.div`
  #MenuStyleDark:hover {
    background-color: #374558;
    color: hsla(0, 0%, 100%, 0.85);
  }
`;
